import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { IValue } from '../models/Value';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ValueState {
    isLoading: boolean;
    id: number;
    values: IValue[];
}   

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestValuesAction {
    type: 'REQUEST_VALUES';
    id: number;
}

interface ReceiveValuesAction {
    type: 'RECEIVE_VALUES';
    id: number;
    values: IValue[];
}

interface ClearLoginsAction {
    type: 'CLEAR_VALUES';
    id: number;
}

interface GetValuesAction {
    type: 'GET_VALUES';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestValuesAction | ReceiveValuesAction | ClearLoginsAction | GetValuesAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestValues: (id: number, url: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();

        if (appState && appState.value && id !== appState.value.id) {
            fetch(url)
                .then(response => response.json() as Promise<IValue[]>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_VALUES', id: id, values: data });
                });

            dispatch({ type: 'REQUEST_VALUES', id: id });
        }
    },
    resetValues: (id: number): AppThunkAction<KnownAction> => (dispatch) => { dispatch({ type: 'CLEAR_VALUES', id: id }); },
    getValues: (): AppThunkAction<KnownAction> => (dispatch) => { dispatch({ type: 'GET_VALUES' }); }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: ValueState = { isLoading: false, id: 0, values: [] };

export const reducer: Reducer<ValueState> = (state: ValueState | undefined, incomingAction: Action): ValueState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'GET_VALUES':
            return {
                id: state.id,
                values: state.values,
                isLoading: false,
            };
        case 'REQUEST_VALUES':
            return {
                id: action.id,
                values: state.values,
                isLoading: true,
            };
        case 'RECEIVE_VALUES':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.id === state.id) {
                return {
                    values: action.values,
                    id: action.id,
                    isLoading: false
                };
            }
            break;
    }

    return state;
};

export const rootReducer = (state: ValueState | undefined, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    if (action.type === 'CLEAR_VALUES') {
        state = undefined;
    }

    return reducer(state, action)
}
