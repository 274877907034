import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Redirect } from 'react-router-dom';
import { Button, Form, Input, Grid } from 'semantic-ui-react';
import { CachedToken, requestToken, isUserAuthenticated } from '../services/AuthenticationService';
import './Home.css'

interface IProps {
}

interface IState {
    username: string;
    password: string;
    clientId: string;
    clientSecret: string;
    status: boolean;
}

type SignInProps =
    IProps
    & RouteComponentProps<{ callbackUrl: string }>; // add incoming routing parameters

class SignIn extends React.Component<SignInProps, IState > {

    public componentDidMount() {
        console.log(this.props.match.params.callbackUrl);
    }

    //Todo:remove hard-coded username and password (development purposes)
    constructor(props: SignInProps) {
        super(props);
        this.state = {
            username: 'admin@mondo', password: 'ss6jiGDBF*B', clientId: "AIMS3D", clientSecret: "AIMS3D4U", status: false
        };
    }

    onSubmit = () => {
        this.setState({ username: '', password:'' });
    }

    updateUsername = (event: any) => {
        this.setState({ username: event.target.value });
    }

    updatePassword = (event: any) => {
        this.setState({ password: event.target.value });
    }

    handleOnClick = () => {
        requestToken(this.state.username, this.state.password, this.state.clientId, this.state.clientSecret).then(response => {
            localStorage.setItem(CachedToken, JSON.stringify({ token: response.data.id_token, refreshToken: response.data.refresh_token }));
            this.setState({ status: !this.state.status });
        }).catch(error => {
            console.log(error);
        });
    }

    render() {
        //Todo: authentication should be done against products/services
        if (isUserAuthenticated("CanAIMS3D")) {
            return (<Redirect to="/" />);
        }

        return (
            <div>
                <Grid centered>
                    <Grid.Row>
                        <Grid.Column>
                            <div className="main-title">
                                <a>Please Sign-In</a>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row stretched columns={3} textAlign='center'>
                        <Grid.Column>
                        </Grid.Column>
                        <Grid.Column textAlign='center'>
                            <Form size="small">
                                <Form.Field>
                                    <Input type="username" value={this.state.username} placeholder='Username' onChange={this.updateUsername} />
                                    <Input type="password" value={this.state.password} placeholder='Password' onChange={this.updatePassword} />
                                </Form.Field>
                                <div>
                                    <Button fluid type='submit' primary onClick={this.handleOnClick}>Login</Button>
                                </div>
                            </Form>
                        </Grid.Column>
                        <Grid.Column>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
            );
    }
}

export default (SignIn);
