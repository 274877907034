import * as React from 'react';
import { RouteComponentProps } from 'react-router';

interface IProps {
}

class About extends React.Component<IProps> {

    public componentDidMount() {
    }

    constructor(props: IProps) {
        super(props);
        this.state = {
        };
    }

    render() {
        var divStyle = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'black'
        };

        return (
            <div style={divStyle}>
                <h1>AIMS Identity Service</h1>
            </div>
            );
    }
}

export default (About);
