import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Grid, TextArea } from 'semantic-ui-react';
import { isUserAuthenticated, removeToken, getTokenClaims } from '../services/AuthenticationService';
import './Home.css'

interface IProps {
}

interface IState {
    username: string;
    password: string;
    status: boolean;
}

class Home extends React.Component<IProps, IState > {

    public componentDidMount() {
    }

    onSubmit = () => {
        this.setState({ username: '', password:'' });
    }

    updateInput = (event: any) => {
        this.setState({ username: event.target.value });
    }

    updateInput2 = (event: any) => {
        this.setState({ password: event.target.value });
    }

    constructor(props: IProps) {
        super(props);
        this.state = {
            username: 'admin@mondo', password: 'ss6jiGDBF*B', status: false
        };
    }

    handleOnSignout = () => {
        removeToken();
        this.setState({ status: !this.state.status });
    }

    getClaimsInfo = () => {
        var claim = getTokenClaims();
        if (claim != null) {
            return claim.name + ", " + claim.aud;
        }
        return "NA";
    }

    getMain = () => {
        return (<div>
            <Grid centered>
                <Grid.Row>
                    <Grid.Column>
                        <div className="main-title">
                            <a>Successfully Signed-In</a>
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched columns={3} textAlign='center'>
                    <Grid.Column>
                    </Grid.Column>
                    <Grid.Column textAlign='center'>
                        <div>
                            <Button fluid type='submit' secondary onClick={this.handleOnSignout}>Sign out</Button>
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row textAlign='center'>
                    <Grid.Column textAlign='center'>
                        <div>Authorization Data</div>
                        <div>{this.getClaimsInfo()}</div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>);
    }

    render() {
        //Todo: authentication should be done against products/services
        if (!isUserAuthenticated("CanAIMS3D")) {
            return (<Redirect to="/SignIn" />);
        }

        return (this.getMain());
    }
}

export default (Home);
