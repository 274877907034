import axios from 'axios';
const axiosApiInstance = axios.create();

export const CachedToken = 'local.authorizationData';
export function getAuthorizationData() {
    const authData = localStorage.getItem(CachedToken);
    if (authData) {
        let data = JSON.parse(authData);
        return data;
    }
    return null;
}

export function getTokenClaims() {
    var authData = getAuthorizationData();
    var token = authData.token;
    if (token) {
        // Return an array of claims
        return JSON.parse(atob(token.split('.')[1]));
    }
    else {
        return null;
    }
}

export function isUserAuthenticated(role: string) {
    try {
        let claims = getTokenClaims();
        console.log(claims);
        if (claims != null) {
            if (claims.role.includes(role)) {
                let tokenExpiryTime = parseInt(claims.exp);
                let timeNow = new Date().getTime() / 1000;
                return tokenExpiryTime > timeNow
            }
        }
        return false;
    } catch (e) {
        console.log("no token - exception: " + e);
        return false;
    }
}

export async function fetchWithAuthorisationHeader(url: string) {
    return await axiosApiInstance.get(url);
}

export async function postWithAuthorisationHeader(url: string, details: any) {
    return await axiosApiInstance.post(url, JSON.stringify(details));
}

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
    async (config: any) => {
        var authData = await getAuthorizationData();
        let token = authData != null ? authData.token : '';

        config.headers = {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        return config;
    },
    (error: any) => {
        Promise.reject(error)
    });

export async function requestToken(username: string, password: string, clientId: string, clientSecret: string) {
    var apiUrl = process.env.REACT_APP_IDENTITY_SERVICE_API_URL + "connect/token";

    const params = new URLSearchParams();
    params.append('grant_type', 'password');
    params.append('client_id', "" + clientId);
    params.append('client_secret', "" + clientSecret);
    params.append('username', username);
    params.append('password', password);
    params.append('scope', 'openid offline_access profile roles');

    return await axios({
        method: "post",
        url: apiUrl,
        data: params,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
}

export function removeToken() {
    localStorage.removeItem(CachedToken);
}
